import { Component, OnInit } from '@angular/core';
declare var $: any;
import { NgForm } from '@angular/forms';
import { CommonService } from '../common/common.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-request-demo',
  templateUrl: './request-demo.component.html',
  styleUrls: ['./request-demo.component.css']
})
export class RequestDemoComponent implements OnInit {

  constructor(private commonService: CommonService, private router: Router) {
    $(document).ready(function () {
      window.history.pushState(null, "", window.location.href);
      window.onpopstate = function () {
        window.history.pushState(null, "", window.location.href);
      };
    });
  }
  public requestForm: any = {};
  public formGroup: any;
  public showLoader: boolean = false;
  public recaptcha_status: boolean = false;
  public recaptcha_token: any;

  clickHeaderBack(kay) {
    console.log(this.router.url)
    if (this.router.url != kay) {
      this.commonService.ChildToSettingPopup({ "title": "back", "value": true });
      setTimeout($.proxy(function () {
        this.router.navigate([kay]);
      }, this), 500, false);
    }
  }

  submitForm(f: NgForm) {
    this.formGroup = f;
    var self = this;
    try {

      if (f.valid && this.recaptcha_token) {

        // this.checkCaptch(this.recaptcha_token, function (res) {
        //   if (res) {

            f.value['form_type'] = 'request-demo';
            f.value['recaptcha_token'] = this.recaptcha_token;
            self.showLoader = true;
            self.commonService.callApi('add_market_form', f.value, 'post', true).then(success => {
              self.showLoader = false;
              if (success.status == 1) {
                if (self.formGroup) {
                  self.formGroup.resetForm();
                }
                self.showLoader = false;
                $("#captBtnId").trigger('click')
                self.commonService.ChildToSettingPopup({ "title": "load", "message": "requesting a demo." });
              } else {
                //console.log(success)
                self.commonService.ChildToParent({ "title": "load", "value": false });
                self.commonService.popToast('error', success.message, 1500)
              }
            });
        //   }
        // })
      }
    } catch (error) {

    }
  }

  clcikSelf() {
    $('html,body').animate({ scrollTop: 0 }, 500);
  }

  ngOnInit() {
    $('html,body').animate({ scrollTop: 0 }, 0);


    /*smooth-scroll*/
    // Select all links with hashes

    try {
      $('.nav-mobi+div').removeClass('navhide');
      $('.navbar-toggler').one('click', function () {
        $('#jabnav').removeAttr('style');
      });
      $('.navbar-toggler').click(function () {
        $('.nav-mobi+div').removeClass('navhide');
        $('body').toggleClass('nav-noscroll');
        $('.nav-mobi').toggleClass('makewhite');
        $('.nav-mobi').toggleClass('animenu');
        $('.navbar-nav').toggleClass('nav-expanded');
      });
      $('body').on('click', 'button[aria-expanded ="true"]', function () {
        setTimeout(function () {
          $('.nav-mobi+div').addClass('navhide');
        }, 400);
      });
      var windwidth = $(window).width();
      if (windwidth < 753) {
        $('.navbar-nav .nav-item').removeClass('cont');
        $('.nav-item .nav-link').click(function () {
          $('.navbar-toggler').trigger('click');
        });
      }

      $('a[href*="#"]')
        // Remove links that don't actually link to anything
        .not('[href="#"]')
        .not('[href="#0"]')
        .click(function (event) {
          // On-page links
          if (
            location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
            &&
            location.hostname == this.hostname
          ) {
            // Figure out element to scroll to
            var target = $(this.hash);
            try {
              target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
            } catch (e) {

            }

            // Does a scroll target exist?
            if (target.length) {
              // Only prevent default if animation is actually gonna happen
              event.preventDefault();
              $('html, body').animate({
                scrollTop: target.offset().top - 94
              }, 1000, function () {
                // Callback after animation
                // Must change focus!
                // var $target = $(target);
                // $target.focus();
                // if ($target.is(":focus")) { // Checking if the target was focused
                //   return false;
                // } else {
                //   $target.attr('tabindex','-1'); // Adding tabindex for elements not focusable
                //   $target.focus(); // Set focus again
                // };
              });
            }
          }

        });
    } catch (e) {

    }

  }

  checkCaptch(captchaResponse, fn) {
    try {
      this.commonService.callApi('verify_captcha', { "g-recaptcha-response": captchaResponse }, 'post', true).then(success => {
        console.log("captchaResponse ", success)

        if (success.status) {
          //  this.recaptcha_status = true
          fn(success.status)
        }

      });
    } catch (error) {

    }
  }

  resolved(captchaResponse: string) {
    try {
      // console.log(`Resolved captcha with response: ${captchaResponse}`);

      this.recaptcha_token = captchaResponse
    } catch (error) {

    }
  }

}
