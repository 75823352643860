import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { CommonService } from '../common/common.service';

@Injectable()
export class CheckAuthGuard implements CanActivate {

    constructor(private commonService: CommonService,private router: Router) {
    	

    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    	//console.log('sdsdsd',state.url.split('/'))
        var splUrl = state.url.split('/')[2];
        this.commonService.setToken('backurl',splUrl);
        if (this.commonService.getToken('accessToken') && (this.commonService.getToken('role') === "client_employer")) {        	
            //this.router.navigate(['/job-id/detail/',splUrl]);
            return true;
        }else if (this.commonService.getToken('accessToken') && (this.commonService.getToken('role') === "candidate")) {
            //this.commonService.setToken('url',splUrl);
            this.router.navigate(['job/candidate-dashboard/detail/',splUrl]);
            return true;
        }
        
        //this.router.navigate(['/authentication/login']);
        return true;
    }
}

 