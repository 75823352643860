import {Routes} from '@angular/router';
import {appSetting} from './app.config';

import { AuthGuard } from './common/auth.gaurd';
import { CheckAuthGuard } from './common/check.auth.gaurd';
import { LoginAuthGuard } from './common/login.auth.gaurd';
import { ErrorComponent } from './error/error.component';

import { HomeComponent } from './home/home.component';
import { PricingComponent } from './pricing/pricing.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { RequestDemoComponent } from './request-demo/request-demo.component';
import { SecurityComponent } from './security/security.component';
import { PublicViewComponent } from './public/public-view.component';
import { ViewJobComponent } from './view-job/view-job.component';
import { TalyComponent } from './taly/taly.component';
import { PublicCandidateSharePageComponent } from './public-candidate-share-page/public-candidate-share-page.component';
import { TermsConditionComponent } from './terms-condition/terms-condition.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { VendorpanelComponent } from './vendorpanel/vendorpanel.component';
import { FreshfreshflowersComponent } from './freshfreshflowers/freshfreshflowers.component';
import { ViktrsComponent } from './viktrs/viktrs.component';


export const AppRoutes: Routes = [

{
  path: '',
  component: HomeComponent
},
{
  path: 'feature',
  component: HomeComponent
},
{
  path: 'jabri_taly_offer',
  component: TalyComponent
},
{
  path: 'pricing',
  component: PricingComponent
},
{
  path: 'contact-us',
  component: ContactUsComponent
},
{
  path: 'security',
  component: SecurityComponent
},
{
  path: 'request-demo',
  component: RequestDemoComponent
},
{
  path: 'link/:id',
  component: PublicCandidateSharePageComponent
},
{
  path: 'jobs/:company/:id',
  component: PublicViewComponent,
},
{
  path: 'jobs/:company/:id/:email',
  component: ViewJobComponent,
},
{
  path: 'terms',
  component: TermsConditionComponent,
},
{
  path: 'privacy-policy',
  component: PrivacyPolicyComponent,
},
{
  path: 'case-study/vendorpanel',
  component: VendorpanelComponent,
},
{
  path: 'freshfreshflowers',
  component: FreshfreshflowersComponent,
},
{
  path: 'viktrs',
  component: ViktrsComponent,
},

{ path: '**', component: HomeComponent}

// { path: '**', redirectTo: 'page-not-found', pathMatch: 'full'},
// { path: 'page-not-found', component: HomeComponent}


];
