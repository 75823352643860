import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { CommonService } from '../common/common.service';

@Injectable()
export class LoginAuthGuard implements CanActivate {

    constructor(private commonService: CommonService,private router: Router) {
    	

    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        ////console.log('role',this.commonService.getToken('role'))
    	////console.log('accessToken',this.commonService.getToken('accessToken'))
        if (this.commonService.getToken('accessToken') && (this.commonService.getToken('role') === "client_employer")) {
            var compnayName = this.commonService.getToken('jobcname').split(' ').join('_').toLowerCase();
            this.router.navigate(['/'+compnayName+'/jobs']);
            return true;
        }else if (this.commonService.getToken('accessToken') && (this.commonService.getToken('role') === "candidate")) {
            this.router.navigate(['/job/candidate-dashboard/profile']);
            return true;
        }        
        return false;
    }
}

 