import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CommonService } from '../common/common.service';
import { Router, ActivatedRoute } from '@angular/router';
declare var $: any;
declare var navigator : any
import { NgForm } from '@angular/forms';
import { Meta, Title } from '@angular/platform-browser';
import { appSetting } from '../app.config';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

    public loggedIn: boolean = true;
    public user: any = {}
    public formGroup: any;
    public showLoader: boolean = false;
    //@ViewChild('videoPlayer') videoplayer: ElementRef;

    constructor(private meta: Meta, private titleService: Title, private commonService: CommonService, private router: Router, private activatedRoute: ActivatedRoute) {

        if (this.commonService.getToken('accessToken')) {
            this.loggedIn = false;
        }
        $(document).ready(function() {
            window.history.pushState(null, "", window.location.href);
            window.onpopstate = function() {
                window.history.pushState(null, "", window.location.href);
            };
        });
        appSetting.frontUrl;

        var logoImg = appSetting.apiUrl + "public/Jabri-Thumb.png";
        var title = "Jabri-Empower Talent Acquisition";
        var desc = "Jabri is a video interview management platform. With Jabri, hiring managers can review, skip, rewind, share, and compare video responses to expert interview questions, on demand";


        this.titleService.setTitle(title);

        this.meta.addTag({ name: "title", content: title });
        this.meta.addTag({ name: "description", content: desc });


        this.meta.addTag({ property: "og:image", content: logoImg });
        this.meta.addTag({ property: "og:title", content: title });
        this.meta.addTag({ property: "og:description", content: desc });
        this.meta.addTag({ property: "og:url", content: "https://jabri.co/" });

        this.meta.addTag({ name: 'twitter:image', content: logoImg });
        this.meta.addTag({ name: 'twitter:url', content: "https://jabri.co/" });
        this.meta.addTag({ name: 'twitter:title', content: title });
        this.meta.addTag({ name: 'twitter:description', content: desc });

        $(document).ready(function() {
            window.history.pushState(null, "", window.location.href);
            window.onpopstate = function() {
                window.history.pushState(null, "", window.location.href);
            };
        });

        //console.log("this.router.url", this.router.url)
        console.log('detectmob', this.detectmob())
        if(this.router.url === '/feature'){
            try{
            if(this.detectmob()){
                if($("#jab-feat-mob")){
                    $('html,body').animate({scrollTop: $("#jab-feat-mob").offset().top},'slow');
                }
            }else{
                if($("#jab-feat-web")){
                    $('html,body').animate({scrollTop: $("#jab-feat-web").offset().top},'slow');
                }
            }
                
            }catch(error){

            }
            
            
        }

    }

     detectmob() {
        if( navigator.userAgent.match(/Android/i)
        || navigator.userAgent.match(/webOS/i)
        || navigator.userAgent.match(/iPhone/i)
        || navigator.userAgent.match(/iPad/i)
        || navigator.userAgent.match(/iPod/i)
        || navigator.userAgent.match(/BlackBerry/i)
        || navigator.userAgent.match(/Windows Phone/i)
        ){
           return true;
         }
        else {
           return false;
         }
    }

    clickHeaderBack(kay){
        console.log(this.router.url)
        if(this.router.url != kay){
          this.commonService.ChildToSettingPopup({"title":"back", "value" :true });
          setTimeout($.proxy(function(){
            this.router.navigate([kay]);
          },this),500, false);
        }
      }

    goToHome() {
        $('html,body').animate({ scrollTop: 0 }, 500);
    }

    toggleVideo() {

        var videoTag = '<video autoplay loop muted playsinline #videoPlayer><source src="assets/video/interview-2.mp4" type="video/mp4" /></video>';
        //this.videoplayer.nativeElement.play();
        $('#videoele1').html(videoTag);
        $('#videoele2').html(videoTag);
    }

    ngOnInit() {

      var self = this;

        try{  

        this.toggleVideo();   
        if(this.router.url != '/feature'){
            $('html,body').animate({ scrollTop: 0 }, 0);
        }       
        
        setTimeout(function() {

            $('#bxfeat').bxSlider({ controls: false, infiniteLoop: false });

            //set animation timing
            var animationDelay = 2500,
                //loading bar effect
                barAnimationDelay = 3800,
                barWaiting = barAnimationDelay - 3000, //3000 is the duration of the transition on the loading bar - set in the scss/css file
                //letters effect
                lettersDelay = 50,
                //type effect
                typeLettersDelay = 150,
                selectionDuration = 500,
                typeAnimationDelay = selectionDuration + 800,
                //clip effect 
                revealDuration = 600,
                revealAnimationDelay = 1500;

            initHeadline();


            function initHeadline() {
                //insert <i> element for each letter of a changing word
                singleLetters($('.cd-headline.letters').find('b'));
                //initialise headline animation
                animateHeadline($('.cd-headline'));
            }

            function singleLetters($words) {
                $words.each(function() {
                    var word = $(this),
                        letters = word.text().split(''),
                        selected = word.hasClass('is-visible');
                    // for (i in letters) {
                    //   if(word.parents('.rotate-2').length > 0) letters[i] = '<em>' + letters[i] + '</em>';
                    //   letters[i] = (selected) ? '<i class="in">' + letters[i] + '</i>': '<i>' + letters[i] + '</i>';
                    // }
                    var newLetters = letters.join('');
                    word.html(newLetters).css('opacity', 1);
                });
            }

            function animateHeadline($headlines) {
                var duration = animationDelay;
                $headlines.each(function() {
                    var headline = $(this);

                    if (headline.hasClass('loading-bar')) {
                        duration = barAnimationDelay;
                        setTimeout(function() { headline.find('.cd-words-wrapper').addClass('is-loading') }, barWaiting);
                    } else if (headline.hasClass('clip')) {
                        var spanWrapper = headline.find('.cd-words-wrapper'),
                            newWidth = spanWrapper.width() + 10
                        spanWrapper.css('width', newWidth);
                    } else if (!headline.hasClass('type')) {
                        //assign to .cd-words-wrapper the width of its longest word
                        var words = headline.find('.cd-words-wrapper b'),
                            width = 0;
                        words.each(function() {
                            var wordWidth = $(this).width();
                            if (wordWidth > width) width = wordWidth;
                        });
                        headline.find('.cd-words-wrapper').css('width', width);
                    };

                    //trigger animation
                    setTimeout(function() { hideWord(headline.find('.is-visible').eq(0)) }, duration);
                });
            }

            function hideWord($word) {
                var nextWord = takeNext($word);

                if ($word.parents('.cd-headline').hasClass('type')) {
                    var parentSpan = $word.parent('.cd-words-wrapper');
                    parentSpan.addClass('selected').removeClass('waiting');
                    setTimeout(function() {
                        parentSpan.removeClass('selected');
                        $word.removeClass('is-visible').addClass('is-hidden').children('i').removeClass('in').addClass('out');
                    }, selectionDuration);
                    setTimeout(function() { showWord(nextWord, typeLettersDelay) }, typeAnimationDelay);

                } else if ($word.parents('.cd-headline').hasClass('letters')) {
                    var bool = ($word.children('i').length >= nextWord.children('i').length) ? true : false;
                    hideLetter($word.find('i').eq(0), $word, bool, lettersDelay);
                    showLetter(nextWord.find('i').eq(0), nextWord, bool, lettersDelay);

                } else if ($word.parents('.cd-headline').hasClass('clip')) {
                    $word.parents('.cd-words-wrapper').animate({ width: '2px' }, revealDuration, function() {
                        switchWord($word, nextWord);
                        //showWord(nextWord);
                    });

                } else if ($word.parents('.cd-headline').hasClass('loading-bar')) {
                    $word.parents('.cd-words-wrapper').removeClass('is-loading');
                    switchWord($word, nextWord);
                    setTimeout(function() { hideWord(nextWord) }, barAnimationDelay);
                    setTimeout(function() { $word.parents('.cd-words-wrapper').addClass('is-loading') }, barWaiting);

                } else {
                    switchWord($word, nextWord);
                    setTimeout(function() { hideWord(nextWord) }, animationDelay);
                }
            }

            function showWord($word, $duration) {
                if ($word.parents('.cd-headline').hasClass('type')) {
                    showLetter($word.find('i').eq(0), $word, false, $duration);
                    $word.addClass('is-visible').removeClass('is-hidden');

                } else if ($word.parents('.cd-headline').hasClass('clip')) {
                    $word.parents('.cd-words-wrapper').animate({ 'width': $word.width() + 10 }, revealDuration, function() {
                        setTimeout(function() { hideWord($word) }, revealAnimationDelay);
                    });
                }
            }

            function hideLetter($letter, $word, $bool, $duration) {
                $letter.removeClass('in').addClass('out');

                if (!$letter.is(':last-child')) {
                    setTimeout(function() { hideLetter($letter.next(), $word, $bool, $duration); }, $duration);
                } else if ($bool) {
                    setTimeout(function() { hideWord(takeNext($word)) }, animationDelay);
                }

                if ($letter.is(':last-child') && $('html').hasClass('no-csstransitions')) {
                    var nextWord = takeNext($word);
                    switchWord($word, nextWord);
                }
            }

            function showLetter($letter, $word, $bool, $duration) {
                $letter.addClass('in').removeClass('out');

                if (!$letter.is(':last-child')) {
                    setTimeout(function() { showLetter($letter.next(), $word, $bool, $duration); }, $duration);
                } else {
                    if ($word.parents('.cd-headline').hasClass('type')) { setTimeout(function() { $word.parents('.cd-words-wrapper').addClass('waiting'); }, 200); }
                    if (!$bool) { setTimeout(function() { hideWord($word) }, animationDelay) }
                }
            }

            function takeNext($word) {
                return (!$word.is(':last-child')) ? $word.next() : $word.parent().children().eq(0);
            }

            function takePrev($word) {
                return (!$word.is(':first-child')) ? $word.prev() : $word.parent().children().last();
            }

            function switchWord($oldWord, $newWord) {
                $oldWord.removeClass('is-visible').addClass('is-hidden');
                $newWord.removeClass('is-hidden').addClass('is-visible');
            }

        }, 1000);
        
        //if (self.showLoader === false) {
            
        //}

        $('.nav-mobi+div').removeClass('navhide');
        $('.navbar-toggler').one('click', function() {
            $('#jabnav').removeAttr('style');
        });
        $('.navbar-toggler').click(function() {
            $('.nav-mobi+div').removeClass('navhide');
            $('body').toggleClass('nav-noscroll');
            $('.nav-mobi').toggleClass('makewhite');
            $('.nav-mobi').toggleClass('animenu');
            $('.navbar-nav').toggleClass('nav-expanded');
        });
        $('body').on('click', 'button[aria-expanded ="true"]', function() {
            setTimeout(function() {
                $('.nav-mobi+div').addClass('navhide');
            }, 400);
        });
        var windwidth = $(window).width();
        if (windwidth < 768) {
            $('.navbar-nav .nav-item').removeClass('cont');
            $('.nav-item .nav-link').click(function() {
                $('.navbar-toggler').trigger('click');
            });
        }

        /*smooth-scroll*/
        // Select all links with hashes
        try {


            $('a[href*="#"]')
                // Remove links that don't actually link to anything
                .not('[href="#"]')
                .not('[href="#0"]')
                .click(function(event) {
                    // On-page links
                    if (
                        location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') &&
                        location.hostname == this.hostname
                    ) {
                        // Figure out element to scroll to
                        var target = $(this.hash);
                        try {
                            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                        } catch (e) {

                        }

                        // Does a scroll target exist?
                        if (target.length) {
                            // Only prevent default if animation is actually gonna happen
                            event.preventDefault();
                            $('html, body').animate({
                                scrollTop: target.offset().top - 94
                            }, 1000, function() {
                                // Callback after animation
                                // Must change focus!
                                // var $target = $(target);
                                // $target.focus();
                                // if ($target.is(":focus")) { // Checking if the target was focused
                                //   return false;
                                // } else {
                                //   $target.attr('tabindex','-1'); // Adding tabindex for elements not focusable
                                //   $target.focus(); // Set focus again
                                // };
                            });
                        }
                    }

                });
        } catch (e) {

        }
        /*smooth-scroll-end*/

        }catch(error){

        }

    }



    submitForm(f: NgForm) {
        this.formGroup = f;
        try {
            if (f.valid) {
                console.log(f.value)
                this.commonService.callApi('send_front_contact', f.value, 'post', true).then(success => {
                    if (success.status == 1) {

                        this.commonService.popToast('success', success.message, 1500);
                        if (this.formGroup) {
                            this.formGroup.resetForm();
                        }
                    } else {
                        this.commonService.popToast('error', "Email not send", 1500)
                    }
                });
            }
        } catch (error) {

        }
    }

    clickDataFeature(i, e) {
        try {
            $('.jabnew-feat-page-item').removeClass('active');
            $('.jabnew-feat-tab').removeClass('active');
            $('.jabnewr').removeClass('active');

            $('.tab' + i).addClass('active')
            $('.feat' + i).addClass('active')
            $('.rigt' + i).addClass('active')
            $(e.target).addClass('active')
        } catch (error) {

        }
    }

    goToDashboard() {
        try {
            var compnayName = this.commonService.getToken('jobcname').split(' ').join('-').toLowerCase();
            this.router.navigate(['/' + compnayName + '/jobs']);
        } catch (error) {

        }
    }

}