import {Component, OnInit} from '@angular/core';
import { Router , ActivatedRoute, RouterStateSnapshot} from '@angular/router';
import { CommonService } from '../common/common.service';
import { Meta, Title} from '@angular/platform-browser';
import {appSetting} from '../app.config';
declare var $:any;
import { Subscription }   from 'rxjs';
//declare var Offline:any;
import * as _ from "lodash";

@Component({
  selector: 'app-public-view',
  templateUrl: './public-view.component.html',
  styleUrls: ['./public-view.component.css']
})
export class PublicViewComponent implements OnInit {

	subscription: Subscription;

  constructor(private meta: Meta,private commonService: CommonService,private router: Router, private activatedRoute:ActivatedRoute, private titleService: Title) { 
  	this.subscription = commonService.missionAnnounced$.subscribe(
      e => {
        //////console.log('1111111111111111')
        this.showLoader(e)
    });
    // this.meta.addTag({ name:"title", content: "Marketing and Campaigns Officer" });
    // this.meta.addTag({ property:"og:type", content:"website" });
    // this.meta.addTag({ property:"og:url", content:"https://app.jabri.co/mark_company/job-5c87a3d0727309351f4c50cf" });
    // this.meta.addTag({ property:"og:title", content:"Marketing and Campaigns Officer" });
    // this.meta.addTag({ property:"og:image", content:"https://api.jabri.co/public/job_thumbnails/thumb_5c87a3d0727309351f4c50cf.png" });
    

    try{
      console.log(this.router.url)
      var JobUrlSplit = this.router.url.split('/');
      if(JobUrlSplit[1] === "view"){
        this.showJobDetails = false;
      }else if(JobUrlSplit[2] === "emp-view"){
        this.showJobDetails = true;
      }
    }catch(error){

    }
    
    try{

      var userRole = this.commonService.getToken('role');
      if (this.commonService.getToken('accessToken') && (userRole === "client_employer" || userRole === "reviewer")) {
      this.showEmpLoginBtn = true;
      }else  if (this.commonService.getToken('accessToken') && (userRole === "candidate")) {
        this.showCandLoginBtn = true;
       }

      this.activatedRoute.params.subscribe( params => {
        ////console.log(params)
        if(params['id']){
          this.jobid = params['id'].split('-')[1];
          this.commonService.setToken('backurl',this.jobid);
          this.meta.addTag({ property:"og:image", content: "https://api.jabri.co/public/job_thumbnails/thumb_"+this.jobid+".png" });
          //////console.log(this.jobid)
          this.getJobDetail(this.jobid);     
          if(this.commonService.getToken('role') === "candidate") {
            this.router.navigate(['job/candidate-dashboard/detail/',params['id']]);
          }
        }else{
          this.router.navigate(['/**']);
        }

      });

    }catch(error){

    }
    
  }

  public jobDetail :any = {};
  public showJobDetails :boolean = false;
  public showEmpLoginBtn :boolean = false;
  public showCandLoginBtn :boolean = false;
  public questionData :any = [];
  public jobUrl :any = '';
  public showjob :boolean = true;
  public step1 :boolean = false;
  public step2 :boolean = false;
  public step3 :boolean = false;
  public jobid :any = '';
  public userIDs :any = this.commonService.getToken("auid");
  public title: string = 'My first AGM project';
  public latM: number;
  public lngM: number; 
  public company_logo :any = '';

  ngOnInit() {

      $(window).scroll(function(){
          if ($(this).scrollTop() >= $('.jabri-app-parent.candidate-layout.public-view-job .jd-action').offset().top) {
             $('h1.public-fixed').addClass('hfix');
             $('.pubview.fixed-title').addClass('fixed');
          } else {
             $('h1.public-fixed').removeClass('hfix');
             $('.pubview.fixed-title').removeClass('fixed');
          }
      });

	  	$('.cand-loader').show();
	    var testArray = [
	    {"rate": 1, "tag": "Values"},
	    {"rate": 1.5, "tag": "Comm"},
	    {"rate": 2, "tag": "Personality"},
	    {"rate": 2.5, "tag": "Problemsolving"},
	    {"rate": 3, "tag": "Values"}];
	    
	    var result=_.chain(testArray).groupBy("tag").map(function(v, i) {
	      return {
	        name: i,
	        count: _.map(v, 'rate').length
	      }
	    }).value();
	  	// Offline.options = {
	   //    // to check the connection status immediatly on page load.
	   //    checkOnLoad: false,

	   //    // to monitor AJAX requests to check connection.
	   //    interceptRequests: true,

	   //    // to automatically retest periodically when the connection is down (set to false to disable).
	   //    reconnect: {
	   //      // delay time in seconds to wait before rechecking.
	   //      initialDelay: 3,

	   //      // wait time in seconds between retries.
	   //      delay: 5
	   //    },

	   //    // to store and attempt to remake requests which failed while the connection was down.
	   //    requests: true
	   //  };
  }

  showLoader(e?){
    ////console.log('load')
    if(e.title == 'load'){
      (e.value)?$('.loadergif').show():$('.loadergif').hide();      
    }
  }

  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.subscription.unsubscribe();
  }

  getJobDetail(jobId?){
  	//////console.log(jobId)
    
    //this.commonService.ChildToParent({"title":"load", "value" :true });
  	this.commonService.callApi('job_detail_allid', {"job_id":jobId, 'alldata':this.showJobDetails}, 'post', true).then(success=>{
        if(success.status == 1){
          console.log(success)
          this.jobDetail = success.data;
          this.latM = success.data.company_Data.lat;
          this.lngM = success.data.company_Data.lng;
          this.company_logo = success.data.company_Data.company_logo;
          this.jobUrl = appSetting.frontUrl+'mark_company/job-'+jobId;
          var fbPreviewImg = appSetting.apiUrl+"public/job_thumbnails/thumb_"+jobId+".png";

          this.titleService.setTitle("Jabri-"+this.jobDetail.title);

          this.meta.addTag({ name:"title", content: this.jobDetail.title });
          this.meta.addTag({ name:"description", content: this.jobDetail.description });


          this.meta.addTag({ property:"og:title", content: this.jobDetail.title });
          //this.meta.addTag({ property:"og:image", content: fbPreviewImg });
          this.meta.addTag({ property:"og:description", content: this.jobDetail.description });
          this.meta.addTag({ property:"og:url", content: this.jobUrl });

          this.meta.addTag({ name: 'twitter:image', content: fbPreviewImg });
          this.meta.addTag({ name: 'twitter:url', content:  this.jobUrl });
          this.meta.addTag({ name: 'twitter:title', content: this.jobDetail.title });
          this.meta.addTag({ name: 'twitter:description', content: this.jobDetail.description });

          if(this.jobDetail.job_fdate && this.jobDetail.job_tdate){
              var fromDate = new Date(this.getDate(this.jobDetail.job_fdate));
              var expDate = new Date(this.getDate(this.jobDetail.job_tdate));
              // Get today's date
              var todaysDate = new Date();
              // call setHours to take the time out of the comparison
              if((expDate.setHours(0,0,0,0) < todaysDate.setHours(0,0,0,0) || this.jobDetail.status === "inactive") && this.commonService.getToken('accessToken')) {
                  ////console.log("expireed 1111111");
                  //this.showjob = false;
                  this.step2 = false;
                  this.step1 = false;
                  this.step3 = true;
              }else if(expDate.setHours(0,0,0,0) < todaysDate.setHours(0,0,0,0) || this.jobDetail.status === "inactive") {
                ////console.log('2222222222')
                  this.step2 = false;
                  this.step1 = false;
                  this.step3 = true;
              }else if(this.commonService.getToken('accessToken') && (fromDate.setHours(0,0,0,0) > todaysDate.setHours(0,0,0,0)) && (expDate.setHours(0,0,0,0) > todaysDate.setHours(0,0,0,0))){
                ////console.log('333333')
                  this.step2 = false;
                  this.step1 = false;
                  this.step3 = true;
              }else if(this.commonService.getToken('accessToken')){
                 ////console.log('44444444')
                  this.step2 = false;
                  this.step1 = true;
                  this.step3 = false;
              }else if((fromDate.setHours(0,0,0,0) > todaysDate.setHours(0,0,0,0)) ||  (expDate.setHours(0,0,0,0) < todaysDate.setHours(0,0,0,0))){
                ////console.log("expireed 55555555");
                  //this.showjob = false;
                  this.step2 = true;
                  this.step1 = false;
                  this.step3 = false;
              }else{
                 ////console.log('555555555')
                  this.step2 = false;
                  this.step1 = true;
                  this.step3 = false;
              }
          }

          //////console.log(this.qnsLength)
          
          $('.cand-loader').hide();
          
        }else{
          //////console.log(success)
          this.commonService.ChildToParent({"title":"load", "value" :false });
          this.commonService.popToast('error',"Invalid Job id",1500)
          this.router.navigate(['/**']);
        }
    });
  }


  checkLogin(){
  	this.router.navigate(['/authentication/login-candidate']); 
  }

  getDate(data){
    var data = data.split('-');
    return data[1]+'/'+data[0]+'/'+data[2];
  }



}
